<app-menu-bar></app-menu-bar>

<p-panel header="Procedimentos Iniciais" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar a plataforma">
            <tr>
                <td>
                    <img src="../../assets/images/PaginaInicial.jpg" class="ImagemPadrao">
                </td>
                <td>
                    <p>
                        Para acessar a plataforma, será necessário acessar através de um navegador (browser). </p>
                       <p> Endereço: https://qa.acelerador-tco.vetorit-solucoes.com.br </p>
                </td>
            </tr>
        </p-tabPanel>
        <p-tabPanel header="Verificando a versão da aplicação">
            <td>
                <img src="../../assets/images/VerificandoVersao.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>
                    Na tela inicial (tela de login), no canto inferior a direita, e possível visualizar a versão da aplicação (incluindo as versões dos componentes do produto).
                </p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Efetuar login">
            <td>
                <img src="../../assets/images/PaginaInicial.jpg" class="ImagemPadrao"><BR>
            </td>
            <td>
                <p>
                    Para efetuar o login na aplicação, deverá ser informado o usuário e a senha.
                </p>
                <p>
                    Após o preenchimento dessas informações, clicar no botão "Entrar".
                </p>
                <p>
                    Caso o login seja efetuado com sucesso, automaticamente será redirecionado para a tela de processamento.
                </p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Menus disponíveis">
            <td>
                <img src="../../assets/images/Menus.jpg" class="ImagemPadrao"><BR>
            </td>
            <td>
                Uma vez que seja feito o login na aplicação, estarão disponíveis os menus abaixo:<BR>
                <ol>
                    <li>Grupos - Através desse menu o usuário será capaz de consultar os dados do grupo a que pertence. Além disso será possível fazer alguma manutenção dos dados.</li>
                    <li>Usuários - Através desse menu o usuário será capaz de fazer a manutenção de usuários, compreendendo a inclusão, alteração e exclusão de usuários.</li>
                    <li>Processamento - Através desse menu o usuário poderá acompanhar os processamentos em execução, submeter um novo processamento, consultar processamentos executados anteriormente, realizar upload de arquivos, etc.</li>
                    <li>Abrir novo ticket - Através desse menu o usuário será capaz de realizar a abertura de um ticket para reportar eventualmente uma ocorrência na aplicação. Ressaltando que só devem ser abertos ticket se tratando de erros de aplicação.</li>
                    <li>Ajuda - Através desse menu o usuário poderá esclarecer dúvidas sobre o funcionamento do produto.</li>
                </ol>
            </td>
        </p-tabPanel>
    </p-tabView>
</p-panel>


<p-panel header="Criando uma programação de Carga" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar o menu Processamento">
            <td>
                <img src="../../assets/images/MenuProcessamento.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>
                    Através do menu principal, acessar o menu denominado "Processamento".
                </p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Clicar no botão Adicionar">
            <td>
                <img src="../../assets/images/BotãoAdicionar.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>
                    Após a abertura da janela de processamento, clicar no botão "Adicionar".
                </p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Selecionar companhia desejada">

            <td>
                <img src="../../assets/images/ProgramacaoCarga.jpg" class="ImagemPadrao">
            </td>

            <td>
                <p>Seleciona companhia.</p>

                 <p> Após selecionar companhia, clicar no botão de "Salvar".</p>

            </td>

        </p-tabPanel>
    </p-tabView>
</p-panel>

<p-panel header="Adicionando Arquivos" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar o menu Processamento">
            <td>
                <img src="../../assets/images/MenuProcessamento.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>Através do menu principal, acessar o menu denominado "Processamento".</p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Upload de Arquivos">
            <td>
                <img src="../../assets/images/BotãoUpload.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>Dentro da lista de processamentos disponíveis, localizar o botão com ícone de Upload.</p>
                <p>Clicar no botao com ícone de Upload.</p>
            </td>
        </p-tabPanel>

        <p-tabPanel header="Selecionar Arquivo">
            <td>
                <img src="../../assets/images/SelecionarArquivo.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>Clicar no botão "Selecionar Arquivo".</p>
            </td>
        </p-tabPanel>

        <p-tabPanel header="Escolha o Arquivo">
            <td>
                <img src="../../assets/images/Upload2.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>Seleciona o arquivo desejado.</p>
                <p>Clicar em "Abrir".</p>
            </td>
        </p-tabPanel>
    </p-tabView>
</p-panel>


<p-panel header="Executando Funcionalidades do Processamento de Carga " [toggleable]="true" [collapsed]="true">
<p-tabView>
    <p-tabPanel header="Acessar o menu Processamento">
     <td>
        <img src="../../assets/images/MenuProcessamento.jpg" class="ImagemPadrao">
         </td>
        <td>
            <p> Através do menu principal, acessar o menu denominado "Processamento". </p>
    </p-tabPanel>

    <p-tabPanel header="Iniciar programação">
     <td>
         <img src="../../assets/images/Execucao1.jpg" class="ImagemPadrao">
         </td>
    <td>
            <p>Dentro da lista de processamentos disponíveis, localizar o botão com ícone de Iniciar programação.</p>
            <p>Clicar no ícone de Iniciar programação.</p>
    </td>
    </p-tabPanel>

    <p-tabPanel header="Cancelar Execução">
     <td>
    <img src="../../assets/images/Execucao2.jpg" class="ImagemPadrao">
         </td>
         <td>
         <p>Dentro da lista de processamentos disponíveis, localizar o botão com ícone de cancelar programação.</p>
        <p>Clicar no botão com ícone de cancelar programação.</p>
        </td>
    </p-tabPanel>


    <p-tabPanel header="Detalhamento do Processo">
             <td>
            <img src="../../assets/images/Execucao3.jpg" class="ImagemPadrao">
             </td>
                <td>
                <p>Dentro da lista de processamentos disponíveis, localizar o botão com ícone de Log de Processamento.</p>
                <p>Clicar no botão com ícone de Log de processamento.</p>
                 </td>
    </p-tabPanel>

    <p-tabPanel header="Exportar Detalhamento">
                <td>
                <img src="../../assets/images/Exportar.jpg" class="ImagemPadrao">
                </td>
                <td>
                <p>Clicar no botão  Exportar Detalhamento.</p>
                <p> Visualizar detalhamento através do arquivo excel que o sistema vai disponibilizar diretamente na sua máquina.</p>
                </td>
    </p-tabPanel>
    </p-tabView>
    </p-panel>

<p-panel header="Filtrando os dados apresentados na janela" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar o menu Processamento">
            <td>
                <img src="../../assets/images/MenuProcessamento.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>Através do menu principal, acessar o menu denominado "Processamento".</p>
                <p>No exemplo apresentado será usado a tela de processamento, todavia o mesmo procedimentos e aplicável a qualquer tela do portal.</p>
            </td>
        </p-tabPanel>

        <p-tabPanel header="Escolher a coluna que se desejar filtrar">
            <td>
                <img src="../../assets/images/FiltrarColunas1.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>Ao escolher a coluna que se deseja filtrar, bastar clicar no funil para que seja apresentado o quadro de definição do filtro.</p>
                <p>Próximo dessa opção, também é possível mudar a ordenação que está sendo apresentado na tela, clicando diretamente nas setas para cima e para baixo.</p>
            </td>
        </p-tabPanel>

        <p-tabPanel header="Preencher os dados do filtro">
            <td>
                <img src="../../assets/images/FiltrarColunas2.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>Preencher o valor que se deseja considerar no filtro. Adicionalmente as condições, exemplo "contenha", "igual a", "iniciado com" e assim por diante.</p>
                <p>Após definido os argumentos para o filtro, clicar no botão "Apply".</p>
            </td>
        </p-tabPanel>
    </p-tabView>
</p-panel>

<p-panel header="Liberando acesso para novos profissionais" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar a manutenção de Usuários">
            <td>
                <img src="../../assets/images/MenuGrupos.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>  Através do menu principal, acessar o menu denominado "Usuários". </p>
            </td>
        </p-tabPanel>

        <p-tabPanel header="Listagem de Usuários">
            <td>
                <img src="../../assets/images/ManutencaoUsuario.jpg" class="ImagemPadrao">
            </td>

            <td>
                <p> Nessa janela, para iniciar a inclusão de um novo usuário, deve-se clicar no botão "Adicionar". </p>
            </td>
        </p-tabPanel>

        <p-tabPanel header="Preencher as informações do novo usuário">
            <td>
                <img src="../../assets/images/InclusaoUsuario.jpg" class="ImagemPadrao">
            </td>

            <td>
                <p> Nessa janela, o usuário conseguirá fazer o preenchimento das informações do usuário.</p>
                <p> É obrigatório o preenchimento dos campos: Nome Usuário, Senha, Status, Acesso, Grupo e Email.</p>
            </td>

        </p-tabPanel>

        <p-tabPanel header="Gravando o usuário">
            <td>
                <img src="../../assets/images/InclusaoUsuario.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p> Após o preenchimento dos campos, clicar no botão "Salvar". </p>
            </td>
        </p-tabPanel>
    </p-tabView>
</p-panel>

<p-panel header="Alterando a senha de acesso" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar o menu Usuário">
            <td>
                <img src="../../assets/images/MenuUsuario.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p> Através do menu principal, acessar o menu denominado "Usuários".</p>
            </td>
        </p-tabPanel>

        <p-tabPanel header="Localizar o usuário que se deseja atualizar">
            <td>
                <img src="../../assets/images/ManutencaoAlteracaoUsuario.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>
                    Localizar o usuário que se deseja alterar.
                </p>
                <p>
                    Na coluna Operações, clicar no primeiro botão para "Editar Usuário".
                </p>
            </td>
        </p-tabPanel>
    <p-tabPanel header="Alterar a informação da senha">
            <td>
                <img src="../../assets/images/AlteracaoUsuario.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>  Ao selecionar um usuário, é possível alterar as suas informações. </p>
                <p>  É recomendado a alteração da senha inicial. </p>
                <p> Ao término das alterações necessárias, clicar no botão "Atualizar". </p>
            </td>
        </p-tabPanel>
    </p-tabView>
</p-panel>

    <p-panel header="Abrindo um ticket para sinalizar uma ocorrência no produto" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar o menu  Abrir novo ticket">
            <td>
                <img src="../../assets/images/MenuTicket.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p> Através do menu principal, acessar o menu denominado "Abrir Novo Ticket (Ocorrência)".</p>
                <p> Também e possível acessar diretamente a abertura de ticket através do link abaixo:<BR>
                    <a href= "https://forms.monday.com/forms/db1e6a471d9822d50a85ed343916466e?r=use1">link</a>
                </p>
            </td>
        </p-tabPanel>

        <p-tabPanel header="Preencher os dados da Ocorrência">
            <td>
                <img src="../../assets/images/AberturaTicket.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>Preencher os dados referente a ocorrência. Deverá ser incluída todas as informações relevantes para análise e diagnóstico do problema.</p>
                <p>Ao término do preenchimento, clicar no botão "Submit" para a abertura do ticket.</p>
                <p>Através do email informado, poderemos entrar em contato para esclarecimentos da ocorrência aberta, caso nao seja suficiente simular o problema.</p>
                <p>Obs: Não deverão ser abertos tickets para problemas de dados. Para esses casos, deve-se criar um novo arquivo com os dados a serem corrigidos e reenviar pelo processamento.</p>
            </td>
        </p-tabPanel>
    </p-tabView>
</p-panel>
