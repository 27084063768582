import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IApiResponse } from '../modelo/PageProps';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
  export class ConfigCNPJEstabService {
    apiUrl: string = `${environment.backendUrl}/config/cnpj`;

    httpOptions = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
      }
    }

    constructor(private http: HttpClient) { }

    listar() {
        return this.http.get<IApiResponse>(`${this.apiUrl}`);
    }
    
    salvar(configuracoes: any): Observable<any> {
        return this.http.post(`${this.apiUrl}`, configuracoes, { headers: this.httpOptions.headers });
    }

  }