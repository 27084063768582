import { Injectable, inject, NgModule } from '@angular/core';
import {  HttpEvent,  HttpInterceptor,  HttpHandler,  HttpRequest} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/service/auth.service';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
   intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
    const authService: AuthService = inject(AuthService);
    
    const token = authService.getToken()

    const clonedRequest = request.clone({
      setHeaders: {
        ContentType: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
      return next.handle(clonedRequest);
   }
}
@NgModule({
   providers: [{
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true,
   }]
})
export class InterceptorModule { }