import { Grupo } from "./Grupo"

export class Usuario {
  public id?: number
  public nome?: string
  public cadastro?: Date
  public status?: string
  public grupo!: Grupo
  public email?: string
  public telefone?: number
  public senha?: string
  public statusDescricao?: string
  public acesso?: string
  public acessoDescricao?: string
}

export enum StatusUsuario{
  ATIVO = "Ativo",
  INATIVO = "Inativo"
}

export enum TipoAcesso{
  SOMENTE_LEITURA = "Somente Leitura",
  LEITURA_GRAVACAO = "Leitura e Gravação"
}
