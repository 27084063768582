import { Usuario } from './../modelo/Usuario';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { IApiResponse } from '../modelo/PageProps';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  apiUrl: string = `${environment.backendUrl}/usuarios`;

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJWZXRvciIsImlzcyI6Im1pY2hlbC5wYWl2YSIsImlhdCI6MTY5NjAwOTA4OCwiZXhwIjoxNzAzODcxNDg4fQ.Z6FkUqBX1Oc9lzEfVPez8mOOjkktMsH5fTnHqfPy78C4WvZS_BmHm3srYuA1qbYWxr3JEQe7TAGMs9SN1eu0iA'
    }
  }

  getUsuarios(pageNumber: number, pageSize: number){
    let searchParams = new HttpParams();

    searchParams = searchParams.append(`page`, String(pageNumber));
    searchParams = searchParams.append(`size`, String(pageSize));

    return this.http.get<IApiResponse>(`${this.apiUrl}`,{params: searchParams, headers: this.httpOptions.headers });
  }

  salavarUsuarios(usuario: Usuario): Observable<any> {
    return this.http.post(`${this.apiUrl}`, usuario, { headers: this.httpOptions.headers });
  }

  editarUsuarios(usuario: Usuario): Observable<any> {
    return this.http.put(`${this.apiUrl}/${usuario.id}`, usuario, { headers: this.httpOptions.headers });
  }

  deletarUsuario(usuario: Usuario): Observable<any> {
    return this.http.delete(`${this.apiUrl}/${usuario.id}`, this.httpOptions);
  }
}
