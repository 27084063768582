import { Component } from '@angular/core';
import { ILogin } from '../@types/ILogin';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth/service/auth.service';
import { lastValueFrom } from 'rxjs';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  public showLoader = false;
  backendVersion: string = "";

  public form = this.fb.group({
    nome: this.fb.control('', [Validators.required]),
    senha: this.fb.control('', [Validators.required]),
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loginService: AuthService,
    private messageService: MessageService){
      this.loginService.getVersion().subscribe(version => {
        this.backendVersion = version;
      });
    }

  show(type: string, titulo: string, msg: string) {
    this.messageService.add({ severity: type, summary: titulo, detail: msg });
  }

  async handleSubmit(): Promise<void> {
    this.showLoader = true;
    try {
      await lastValueFrom(this.loginService.signIn(this.form.value as ILogin));
      this.router.navigate(['/manutencao-processamento']);
    } catch (error){
      this.messageService.add({
        severity: 'error',
        summary: 'Login',
        detail: 'Erro ao tentar fazer login!',
      });
    }
    this.showLoader = false;
  }

}
