import { AuthService } from '../core/auth/service/auth.service';
import { catchError } from 'rxjs/operators';
import { Component } from '@angular/core';
import { ConfigCNPJEstabService } from './config-cnpj-estab.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { PageProps } from '../modelo/PageProps';
import { throwError } from 'rxjs';

@Component({
    selector: 'app-config-cnpj-estab',
    templateUrl: './config-cnpj-estab.component.html',
    styleUrls: ['./config-cnpj-estab.component.css'],
    providers: []
  })
  export class ConfigCNPJEstabComponent {

    constructor(
      private auth: AuthService,
      private messageService: MessageService,
      private service: ConfigCNPJEstabService) {
    }

    lifeMessageToast: number = 5000;

    configuracoes: any; 

    configuracaoListaPage: PageProps<any> = {} as PageProps<any>;
    configuracaoPage: PageProps<any> = {} as PageProps<any>;
  
    async ngOnInit() {
      this.fetchContent();
    }

    salvarConfig(dados: any) {
      if (!this.auth.isAuthenticated())
        return;
      this.service.salvar(dados)
      .pipe(
        catchError(error => {
            this.messageService.add({
              severity: 'error',
              summary: 'Configuração CNPJ',
              detail: 'Erro ao salvar configuração CNPJ. ' + error.error.error,
              life: this.lifeMessageToast,
            });
            return throwError(error);
        }))
        .subscribe((response) => {
          this.fetchContent();
          this.messageService.add({
            severity: 'success',
            summary: 'Configuração CNPJ',
            detail: 'Configuração de CNPJ gravado com sucesso!',
            life: this.lifeMessageToast});
      });    
    }

    public fetchContent(): void {
      if (!this.auth.isAuthenticated())
        return;
      this.service.listar().subscribe({
        next: (payload) => {
          this.configuracaoPage = payload;
          this.configuracoes = this.configuracaoPage.content;
        },
        error: (error: HttpErrorResponse) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Configurações CNPJs',
            detail: 'Erro ao tentar buscar configurações de CNPJ',
            life: this.lifeMessageToast,
          });
        },
      });
    }
    
}