import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IApiResponse } from '../modelo/PageProps';
import { Processamento } from '../modelo/Processamento';
import { Observable } from 'rxjs';
import { ProcessamentoLista } from '../modelo/ProcessamentoLista';

@Injectable({
  providedIn: 'root'
})
export class ProcessamentoService {
  apiUrl: string = `${environment.backendUrl}/processamento`;

  httpOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
    }
  }

  httpOptionsMultiPart = {
    headers: {
        'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
    }
  }

  constructor(private http: HttpClient) { }

  uploadFile(file: File, processamento: Processamento){
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${this.apiUrl}/arquivos/${processamento.uuid}`,
    formData, { headers: this.httpOptionsMultiPart.headers ,  observe: 'response', responseType: 'text' });
  }

  listar() {
    return this.http.get<IApiResponse>(`${this.apiUrl}`);
  }

  listarArquivos(processamento: Processamento) {
    return this.http.get<IApiResponse>(`${this.apiUrl}/arquivos/${processamento.uuid}`);
  }

  listarErros(processamento: Processamento) {
    return this.http.get<IApiResponse>(`${this.apiUrl}/tipoerros/${processamento.uuid}`);
  }

  salvar(processamento: Processamento): Observable<any> {
    return this.http.post(`${this.apiUrl}`, processamento, { headers: this.httpOptions.headers });
  }

  salvarArquivo(processamentoLista: ProcessamentoLista) {
    return this.http.post(`${this.apiUrl}/arquivos/salvar/${processamentoLista.id}`, processamentoLista, { headers: this.httpOptions.headers });
  }

  excluirArquivo(processamentoLista: ProcessamentoLista) {
    return this.http.delete(`${this.apiUrl}/arquivos/${processamentoLista.id}`);
  }

  solicitaProcessamento(processamento: Processamento) {
    return this.http.put(`${this.apiUrl}/inicia/${processamento.uuid}`, null, { headers: this.httpOptions.headers });
  }

  solicitaCancelamento(processamento: Processamento) {
    return this.http.put(`${this.apiUrl}/cancelar/${processamento.uuid}`, null, { headers: this.httpOptions.headers });
  }

  exportar(processamento: Processamento, apenasErros: boolean): Observable<Blob> {
    return this.http.post<any>(`${this.apiUrl}/exportacao/${processamento.uuid}/${apenasErros}`, null,
    { responseType: 'blob' as 'json' });
  }

  statusProtocolo(processamento: Processamento): Observable<Blob> {
    return this.http.post<any>(`${this.apiUrl}/protocolos/${processamento.uuid}`, null,
    { responseType: 'blob' as 'json' });
  }
}
