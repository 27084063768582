import { ConfirmationService, MessageService } from 'primeng/api';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { Component, ViewChild } from '@angular/core';
import { ProcessamentoListaService } from './processamento-lista.service';
import { interval, throwError } from 'rxjs';
import { AuthService } from '../core/auth/service/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SharedDataService } from '../shared/shared-data/shared-data.service';
import { ProcessamentoLista } from '../modelo/ProcessamentoLista';

@Component({
  selector: 'app-processamento-lista',
  templateUrl: './processamento-lista.component.html',
  styleUrls: ['./processamento-lista.component.css'],
  providers: [MessageService, ConfirmationService, FileUploadModule]
})
export class ProcessamentoListaComponent {
  @ViewChild(FileUpload) fileUpload!: FileUpload;

  uploadDialog: boolean = false;
  loading: boolean = false;
  lifeMessageToast: number = 5000;

  processamentos: ProcessamentoLista[] = [];

  processamentoArquivoId: String | undefined | null;

  constructor(
    private messageService: MessageService,
    private service: ProcessamentoListaService,
    private sharedData: SharedDataService,
    private auth: AuthService
  ) {}

  async ngOnInit() {
    this.processamentoArquivoId = this.sharedData.getProcessamentoArquivo();
    await this.fetchContent();
      interval(1000) // Atualiza a cada 5 segundos (5000 milissegundos)
      .subscribe(() => {
      this.fetchContent();
        }
      );
  }

  getIconClass(status: string): String | undefined | null {
    if (status == 'CONCLUIDO')
      return 'pi pi-check p-button-success';
    if (status == 'FALHA')
      return 'pi pi-times p-button-danger';
    if (status == 'AVISOS')
      return 'pi pi-exclamation-triangle p-button-warning';
    if (status == 'PENDENTE')
      return 'pi pi-stopwatch p-button-help';
    if (status == 'EM_ANDAMENTO')
      return 'pi pi-sync pi-spin p-button-info';
    return '';
  }

  public fetchContent(): void {
    if (!this.auth.isAuthenticated())
      return;
    this.loading = true;
    this.service.getListas(this.processamentoArquivoId).subscribe({
      next: (payload: ProcessamentoLista[]) => {
        this.processamentos = payload;
    },

      error: (error: HttpErrorResponse) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Processamento Lista',
          detail: 'Erro ao tentar buscar lista de arquivos!',
          life: this.lifeMessageToast,
        });
        console.error('Erro: ' + error);
      },
    });
    this.loading = false;
  }

  detalhaLista(processamento: ProcessamentoLista) {
    this.sharedData.setProcessamentoLista(processamento.id);
    const url = '/registros';
    window.open(url, '_blank');
  }

  exportarDados(processamento: ProcessamentoLista) {
    this.service.getExportacoes(processamento.id).subscribe(response => {
      console.log('Dados: ' + response);
      const blob = new Blob([response], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = 'exportacao_lista_' + processamento.id + '_' +
       processamento.nomeLista + '_' + new Date().toLocaleDateString() + '_' + new Date().toLocaleTimeString() + '.xlsx'
      link.href = url;
      link.click();
      window.URL.revokeObjectURL(url);
    })
  }
}
