import { Status } from "./Status"

export class Processamento {
  public uuid?: string
  public codigoCia?: string
  public usuarioResponsavel?: string
  public dataInicio?: Date
  public dataTermino?: Date
  public status?: Status

  public procFiliais: boolean = false;
  public procMateriais: boolean = false;
  public procParticipantes: boolean = false;
  public procNotasFiscais: boolean = false;
  public procAuditoriaFiscal: boolean = false;
  public procEstabelecimentoFiscalECF: boolean = false;
  public procParteALalur: boolean = false;
  public procParteBLalur: boolean = false;
  public procPlanoContas: boolean = false;
  public procCentroCusto: boolean = false;
  public procLancamentosContabeis: boolean = false;
  public procSaldosContabeis: boolean = false;
  public procPlanoReferencial: boolean = false;
  public procAglutinacoes: boolean = false;
  public procLivroContabil: boolean = false;

}
