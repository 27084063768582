<app-menu-bar></app-menu-bar>
<div class="processamento-container">
    <div style="text-align: center">Processamento de Carga</div>
    <p-toolbar>
      <ng-template pTemplate="right">
        <div class="card flex justify-content-center">
          <p-toast></p-toast>
        </div>
        <button
          pButton
          pRipple
          icon="pi pi-plus"
          label="Adicionar"
          class="p-button-info mr-2"
          (click)="openNew()"
        ></button>
      </ng-template>
    </p-toolbar>

    <div class="table-container">
        <p-table
        styleClass="p-datatable-gridlines"
        [tableStyle]="{ 'min-width': '50rem' }"
        [paginator]="true"
        [rows]="15"
        [rowsPerPageOptions]="[10, 15, 20]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} até {last} (Total de Registros: {totalRecords})"
        styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
        [value]="processamentos"
        [globalFilterFields]="[
          'dados.uuid',
          'dados.usuarioResponsavel',
          'dados.codigoCia',
          'dados.data_inicio',
          'dados.data_termino',
          'dados.status']">
            <ng-template pTemplate="header">
                <tr>
                  <th id="operacao" style="text-align: center"></th>
                  <th id="uuid" pSortableColumn="uuid">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Id
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="uuid"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="uuid"
                              display="menu"
                              matchMode="contains"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>

                      <th id="usuarioResponsavel" pSortableColumn="usuarioResponsavel">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Responsável
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="usuarioResponsavel"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="usuarioResponsavel"
                              display="menu"
                              matchMode="contains"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>

                      <th id="codigoCia" pSortableColumn="codigoCia">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Companhia
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="codigoCia"></p-sortIcon>
                            <p-columnFilter
                            type="text"
                              field="codigoCia"
                              display="menu"
                              matchMode="contains">
                            </p-columnFilter>
                          </div>
                        </div>
                      </th>

                      <th id="dataInicio" pSortableColumn="dataInicio">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Início
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="dataInicio"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="dataInicio"
                              display="menu"
                              matchMode="contains"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>

                      <th id="dataTermino" pSortableColumn="dataTermino">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Término
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="dataTermino"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="dataTermino"
                              display="menu"
                              matchMode="contains"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>
                      <th id="status" style="text-align: left">Status</th>
                      <th style="text-align: center">

                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-dados>
                <tr>
                  <td style="text-align: center">
                    <i *ngIf="['Aguardando arquivos', 'Pronto para iniciar'].includes(dados.status)"
                      class="pi pi-cloud-upload" style="color: blue; font-size: 1.5rem" pTooltip="Upload Arquivos"
                      (click)="upload(dados)">&nbsp;</i>
                    <i *ngIf="['Pronto para iniciar'].includes(dados.status)"
                      class="pi pi-play" style="color: green; font-size: 1.5rem" pTooltip="Iniciar Programação"
                      (click)="solicitaProcessamento(dados)">&nbsp;</i>
                    <i *ngIf="['Em processamento'].includes(dados.status)"
                      class="pi pi-stop" style="color: red; font-size: 1.5rem" pTooltip="Solicitar Cancelamento"
                      (click)="solicitaCancelamento(dados)">&nbsp;</i>
                    <i *ngIf="['Em processamento', 'Processamento cancelado', 'Concluído com sucesso', 'Falha processamento'].includes(dados.status)"
                      class="pi pi-book" style="color: orange; font-size: 1.5rem" pTooltip="Log de Processamento"
                      (click)="exibeLogProcessamento(dados)">&nbsp;</i>
                    <i *ngIf="['Processamento cancelado', 'Concluído com sucesso', 'Falha processamento'].includes(dados.status)"
                       class="pi pi-file-excel" style="color: green; font-size: 1.5rem" pTooltip="Verifica Status Protocolos"
                       (click)="verificaStatusProtocolos(dados)"></i>
                    </td>
                  <td style="text-align: left">{{ dados.uuid }}</td>
                    <td style="text-align: left">{{ dados.usuarioResponsavel }}</td>
                    <td style="text-align: left">{{ dados.codigoCia }}</td>
                    <td style="text-align: left">{{ dados.dataInicio | date : "yyyy-MM-dd HH:mm:ss" }}</td>
                    <td style="text-align: left">{{ dados.dataTermino | date : "yyyy-MM-dd HH:mm:ss" }}</td>
                    <td><i [class]="getIconClass(dados.status)"></i>&nbsp; {{ dados.status }}<BR></td>
                    <td style="text-align: center">
                      <i class="pi pi-exclamation-circle" style="color: blue; font-size: 1.4rem"
                        pTooltip="{{ dados.informacoesComplementares}}" tooltipPosition="left">&nbsp;</i>
                         </tr>
            </ng-template>
        </p-table>

    </div>
    <p-confirmDialog
      appendTo="body"
      icon="pi pi-exclamation-triangle"
      [position]="'top'"
      [baseZIndex]="10000">
    </p-confirmDialog>

    <p-dialog
      header="Programação de nova carga"
      [(visible)]="visible"
      [style]="{ width: '1050px', height: '800px' }"
      [modal]="true"
      (onHide)="hideDialog()"
      styleClass="p-fluid">
      <ng-template pTemplate="header">
        <h2>Nova programação de carga</h2>
      </ng-template>
      <ng-template pTemplate="content">
        <label for="codigoCia">Companhia</label>
        <p-dropdown
          [options] = "listaCompanhias"
          optionValue="codigoCIA"
          optionLabel="descricao"
          [(ngModel)]="processamento.codigoCia">
        </p-dropdown>
        <p>Selecione abaixo os dados que serao recuperados dos arquivos dos SPEDs (Fiscal, Contabil, Contribuiçoes e ECF) para serem gravados no Tax Compliance:</p>
<!--        <p>Gerais</p>
        <p-toggleButton [(ngModel)]="processamento.procFiliais" onLabel="Filiais" offLabel="Filiais" onIcon="pi pi-check" offIcon="pi pi-stop" styleClass="w-15rem"/>-->
        <p>Dados fiscais</p>
        <p-toggleButton [(ngModel)]="processamento.procMateriais" onLabel="Materiais" offLabel="Materiais" onIcon="pi pi-check" offIcon="pi pi-stop" styleClass="w-15rem"/>&nbsp;
        <p-toggleButton [(ngModel)]="processamento.procParticipantes" onLabel="Clientes/Fornecedores" offLabel="Clientes/Fornecedores" onIcon="pi pi-check" offIcon="pi pi-stop" styleClass="w-15rem"/>&nbsp;
        <p-toggleButton [(ngModel)]="processamento.procNotasFiscais" onLabel="Notas Fiscais" offLabel="Notas Fiscais" onIcon="pi pi-check" offIcon="pi pi-stop" styleClass="w-15rem"/>&nbsp;
<!--        <p-toggleButton [(ngModel)]="processamento.procAuditoriaFiscal" onLabel="Auditoria Fiscal" offLabel="Auditoria Fiscal" onIcon="pi pi-check" offIcon="pi pi-stop" styleClass="w-15rem"/> -->
        <p>Dados contabeis</p>
        <p-toggleButton [(ngModel)]="processamento.procParteALalur" onLabel="Parte A - Lalur / ECF" offLabel="Parte A - Lalur / ECF" onIcon="pi pi-check" offIcon="pi pi-stop" styleClass="w-15rem"/>&nbsp;
        <p-toggleButton [(ngModel)]="processamento.procParteBLalur" onLabel="Parte B - Lalur / ECF" offLabel="Parte B - Lalur / ECF" onIcon="pi pi-check" offIcon="pi pi-stop" styleClass="w-15rem"/>&nbsp;
        <p-toggleButton [(ngModel)]="processamento.procPlanoContas" onLabel="Plano de Contas" offLabel="Plano de Contas" onIcon="pi pi-check" offIcon="pi pi-stop" styleClass="w-15rem"/>&nbsp;
        <p-toggleButton [(ngModel)]="processamento.procCentroCusto" onLabel="Centro de Custo" offLabel="Centro de Custo" onIcon="pi pi-check" offIcon="pi pi-stop" styleClass="w-15rem"/>&nbsp;<BR><BR>
        <p-toggleButton [(ngModel)]="processamento.procLancamentosContabeis" onLabel="Lançamentos" offLabel="Lançamentos" onIcon="pi pi-check" offIcon="pi pi-stop" styleClass="w-15rem"/>&nbsp;
        <p-toggleButton [(ngModel)]="processamento.procSaldosContabeis" onLabel="Saldos Contabeis" offLabel="Saldos Contabeis" onIcon="pi pi-check" offIcon="pi pi-stop" styleClass="w-15rem"/>&nbsp;
        <p-toggleButton [(ngModel)]="processamento.procPlanoReferencial" onLabel="Plano Referencial" offLabel="Plano Referencial" onIcon="pi pi-check" offIcon="pi pi-stop" styleClass="w-15rem"/>&nbsp;
        <p-toggleButton [(ngModel)]="processamento.procAglutinacoes" onLabel="Aglutinaçao" offLabel="Aglutinaçao" onIcon="pi pi-check" offIcon="pi pi-stop" styleClass="w-15rem"/>
      </ng-template>
      <ng-template pTemplate="footer">
        <p-button label="Marcar todos" [rounded]="true" tooltip="Selecionar Todos" styleClass="p-button-secondary" (click)="marcarTodos()"/>
        <p-button label="Desmarcar todos" [rounded]="true" tooltip="Desmarcar Todos" styleClass="p-button-secondary" (click)="desmarcarTodos()"/>
        <BR><BR>
        <button pButton label="Salvar" icon="pi pi-check" class="b-salvar" (click)="salvar()">
        </button>
        <p-button pRipple label="Cancelar" icon="pi pi-times" class="b-cancelar" styleClass="p-button-secondary" (click)="hideDialog()">
        </p-button>
      </ng-template>
    </p-dialog>

    <!-- Dialog de inserir anexo -->
    <p-dialog
      header="Upload de novo arquivo"
      [(visible)]="uploadDialog"
      [modal]="true"
      styleClass="p-fluid"
    >
      <ng-template pTemplate="content">
        <div>
            <div class="upload-container">
                <p-fileUpload name="file[]"
                    [customUpload]="true"
                    (uploadHandler)="uploadFile($event)"
                    (onUpload)="uploadFile($event)"
                    [multiple]="false"
                    accept=".txt, .zip"
                    mode="basic"
                    [auto]="true"
                    [maxFileSize]="100000000"
                    chooseLabel="Selecionar Arquivo"
                    cancelLabel="Cancelar"
                    uploadLabel="Enviar"
                    uploadIcon="pi pi-cloud-upload">
                </p-fileUpload>
            </div>

            <div class="table-container" height="1000px">
              <p-table [value]="processamentoListas" [globalFilterFields]="['nome']" class="custom-table">
                <ng-template pTemplate="header">
                  <tr>
                    <th id="id">Id</th>
                    <th id="cadastro">Cadastro</th>
                    <th id="nomeArquivo">Arquivo</th>
                    <th id="tamanho">Tamanho</th>
                    <th id="tipoObrigacao">Tipo Obrigaçao</th>
                    <th id="status">Status</th>
                    <th id="operacao">Operação</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-upload>
                  <tr>
                    <td>{{ upload.id }}</td>
                    <td>{{ upload.cadastro }}</td>
                    <td>{{ upload.nomeArquivo }}</td>
                    <td>{{ upload.tamanho }}</td>

                    <td [pEditableColumn]="upload.tipoObrigacao" style="text-align: left">
                      <div class="flex flex-column gap-3">
                        <div class="flex align-items-center">
                          <p-radioButton
                          [(ngModel)]="upload.tipoObrigacao"
                          value="Não definido"
                          label="Não definido"
                          optionLabel="label"
                          placeholder="Tipo de Obrigacao" />
                        </div>
                        <div class="flex align-items-center">
                          <p-radioButton
                          [(ngModel)]="upload.tipoObrigacao"
                          value="SPED Fiscal"
                          label="SPED FISCAL"
                          optionLabel="label"
                          placeholder="Tipo de Obrigacao" />
                        </div>
                        <div class="flex align-items-center">
                          <p-radioButton
                          [(ngModel)]="upload.tipoObrigacao"
                          value="SPED Contabil"
                          label="SPED Contabil"
                          optionLabel="label"
                          placeholder="Tipo de Obrigacao" />
                        </div>
                        <div class="flex align-items-center">
                          <p-radioButton
                          [(ngModel)]="upload.tipoObrigacao"
                          value="E-Contribuiçoes"
                          label="E-Contribuiçoes"
                          optionLabel="label"
                          placeholder="Tipo de Obrigacao" />
                        </div>
                        <div class="flex align-items-center">
                          <p-radioButton
                          [(ngModel)]="upload.tipoObrigacao"
                          value="ECF"
                          label="ECF"
                          optionLabel="label"
                          placeholder="Tipo de Obrigacao" />
                        </div>
                    </div>
                    </td>

                    <td>{{ upload.status }}</td>
                    <td>
                      <p-button icon="pi pi-check-circle" pTooltip="Salvar"
                        styleClass="p-button-success" (click)="salvarArquivo(upload)"></p-button>&nbsp;
                      <p-button icon="pi pi-times-circle" pTooltip="Excluir"
                        styleClass="p-button-danger" (click)="excluirArquivo(upload)"></p-button>&nbsp;
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
        </div>
      </ng-template>
    </p-dialog>

    <p-dialog header="Detalhamento do Processamento"
    [(visible)]="detalhamentoDialog"
    [modal]="true"
    styleClass="p-fluid"
    (onHide)="hideDetalhamentoDialog()">
      <ng-template pTemplate="content">
        <table class="custom-table" style="width: 100%;">
          <tr>
            <th style="text-align: left;border-width: 1px;">id</th>
            <th style="text-align: left;border-width: 1px;">Companhia</th>
            <th style="text-align: left;border-width: 1px;">Responsável</th>
            <th style="text-align: left;border-width: 1px;">Período</th>
          </tr>
            <tr>
              <td style="text-align: left;">{{ processamento.uuid }}</td>
              <td style="text-align: left;">{{ processamento.codigoCia }}</td>
              <td style="text-align: left;">{{ processamento.usuarioResponsavel }}</td>
              <td style="text-align: left;">{{ processamento.dataInicio | date: 'dd/MM/yyyy' }} a {{ processamento.dataTermino | date: 'dd/MM/yyyy' }}</td>
            </tr>
        </table>
        <p-toolbar>
          <ng-template pTemplate="right">
            <div class="card flex justify-content-center">
              <p-toast></p-toast>
            </div>
            <button
              pButton
              pRipple
              icon="pi pi-plus"
              label="Exportar Detalhamento"
              class="p-button-info mr-2"
              (click)="exportar()">
            </button><br>
          </ng-template>
          <ng-template pTemplate="left">
            <div>Logs:&nbsp;</div>
            <p-toggleButton [(ngModel)]="apenasErros"
              onLabel= "Apenas Erros"
              offLabel="Todos os logs"
              onIcon="pi pi-check"
              offIcon="pi pi-times"
              [style]="{ width: '15em' }">
            </p-toggleButton>
          </ng-template>
        </p-toolbar>
        <p-tabView>
          <p-tabPanel header="Resumo">
            <div  class="table-container">
              <p-table [value]="processamentoListas" class="custom-table">
                <ng-template pTemplate="header">
                  <tr>
                    <th id="id" pSortableColumn="id">Id<p-sortIcon field="id"></p-sortIcon></th>
                    <th id="cadastro" pSortableColumn="cadastro">Cadastro<p-sortIcon field="cadastro"></p-sortIcon></th>
                    <th id="nomeArquivo" pSortableColumn="nomeArquivo">Arquivo<p-sortIcon field="nomeArquivo"></p-sortIcon></th>
                    <th id="tamanho" pSortableColumn="tamanho">Tamanho<p-sortIcon field="tamanho"></p-sortIcon></th>
                    <th id="status" pSortableColumn="status">Status<p-sortIcon field="status"></p-sortIcon></th>
                    <th id="totalRegistros" pSortableColumn="totalRegistros">Total Registros<p-sortIcon field="totalRegistros"></p-sortIcon></th>
                    <th id="totalInseridos" pSortableColumn="totalInseridos">Processados<p-sortIcon field="totalInseridos"></p-sortIcon></th>
                    <th id="totalErrados" pSortableColumn="totalErrados">Erros<p-sortIcon field="totalErrados"></p-sortIcon></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-lista>
                  <tr>
                    <td>{{ lista.id }}</td>
                    <td>{{ lista.cadastro }}</td>
                    <td>{{ lista.nomeArquivo }}</td>
                    <td>{{ lista.tamanho }}</td>
                    <td>{{ lista.status }}</td>
                    <td style="color: blue">{{ lista.totalRegistros }}</td>
                    <td style="color: green">{{ lista.totalInseridos + lista.totalAlterados }}</td>
                    <td style="color: red;">{{ lista.totalErrados }}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Resumo por Tipo">
            <div  class="table-container">
              <p-table [value]="processamentoErrosTIpo" class="custom-table">
                <ng-template pTemplate="header">
                  <tr>
                    <th id="nomeArquivo" pSortableColumn="nomeArquivo">Arquivo<p-sortIcon field="nomeArquivo"></p-sortIcon></th>
                    <th id="description" pSortableColumn="description">Descrição<p-sortIcon field="description"></p-sortIcon></th>
                    <th id="total" pSortableColumn="total">Total<p-sortIcon field="total"></p-sortIcon></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-erro>
                  <tr>
                    <td>{{ erro.nomeArquivo }}</td>
                    <td>{{ erro.description }}</td>
                    <td>{{ erro.total }}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </p-tabPanel>
        </p-tabView>
      </ng-template>
    </p-dialog>

    <p-dialog header="Processando" [(visible)]="display" [modal]="true" [closable]="false">
      <p>Por favor, aguarde...</p>
    </p-dialog>
</div>
