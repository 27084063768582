import { environment } from '../../environments/environment';
import { Grupo } from '../modelo/Grupo';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IApiResponse } from '../modelo/PageProps';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GruposService {

  apiUrl: string = `${environment.backendUrl}/grupos`;

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImlzcyI6Im1pY2hlbC5wYWl2YSIsImlhdCI6MTY5NjUxNTI5NCwiZXhwIjoxNjk2NjAxNjk0fQ.nHh3lsmG0eghQAbk7ZFeERK5GpkaxEMMaXHNlMDCybnHHvik8tphxYlxrBiCkSvZTl52zabqmP5mBXujCxYTqw'
    }
  }

  getGrupos (){
    let searchParams = new HttpParams();

    return this.http.get<IApiResponse>(`${this.apiUrl}`,
    { params: searchParams, headers: this.httpOptions.headers });
  }

  getGruposAll (pageNumber: number, pageSize: number){
    let searchParams = new HttpParams();

    searchParams = searchParams.append(`page`, String(pageNumber));
    searchParams = searchParams.append(`size`, String(pageSize));

    return this.http.get<IApiResponse>(`${this.apiUrl}`,
    { params: searchParams, headers: this.httpOptions.headers });
  }

  getHistorico(pageNumber: number, pageSize: number){
    let searchParams = new HttpParams();

    searchParams = searchParams.append(`page`, String(pageNumber));
    searchParams = searchParams.append(`size`, String(pageSize));

    return this.http.get<any>(`${this.apiUrl}/aud`,
    {params: searchParams, headers: this.httpOptions.headers });
  }

  salvarGrupo(grupo: Grupo): Observable<any> {
    return this.http.post(`${this.apiUrl}`, grupo, { headers: this.httpOptions.headers });
  }

  editarGrupo(grupo: Grupo): Observable<void>{
    return this.http.put<void>(`${this.apiUrl}/${grupo.id}`, grupo, { headers: this.httpOptions.headers})
  }

  deletarGrupo(id: number): Observable<any> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`,  { headers: this.httpOptions.headers})
  }
}
