<app-menu-bar></app-menu-bar>
<div class="processamento-container">
    <div style="text-align: center">Processamento Lista</div>
    <div class="table-container">
        <p-table
        styleClass="p-datatable-gridlines"
        [tableStyle]="{ 'min-width': '50rem' }"
        [paginator]="true"
        [rows]="15"
        [rowsPerPageOptions]="[10, 15, 20]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} até {last} (Total de Registros: {totalRecords})"
        styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
        [value]="processamentos">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="id">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Id
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="id"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="id"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>

                      <th pSortableColumn="processamentoArquivo.nomeArquivo">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Arquivo
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="processamentoArquivo.nomeArquivo"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="processamentoArquivo.nomeArquivo"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>

                      <th pSortableColumn="nomeLista">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Nome Lista
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="nomeLista"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="nomeLista"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>

                      <th pSortableColumn="dataInicio">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Início
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="dataInicio"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="dataInicio"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>

                      <th pSortableColumn="dataTermino">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Termino
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="dataTermino"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="dataTermino"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>

                      <th pSortableColumn="quantidadeLinhas">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                             Quantidade Linhas
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="quantidadeLinhas"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="quantidadeLinhas"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>
                      <th style="text-align: center">Status</th>
                      <th style="text-align: center">Operação</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-dados>
                <tr>
                    <td style="text-align: center">{{ dados.id }}</td>
                    <td style="text-align: center">{{ dados.processamentoArquivo.nomeArquivo }}</td>
                    <td style="text-align: center">{{ dados.nomeLista }}</td>
                    <td style="text-align: center">{{ dados.dataInicio | date : "yyyy-MM-dd HH:mm:ss" }}</td>
                    <td style="text-align: center">{{ dados.dataTermino | date : "yyyy-MM-dd HH:mm:ss" }}</td>
                    <td style="text-align: center">{{ dados.quantidadeLinhas }}</td>
                    <td style="text-align: left">
                      <i [class]="getIconClass(dados.statusLerLista)"></i>&nbsp;Leitura Dados<BR>
                      <i [class]="getIconClass(dados.statusConverteLista)"></i>&nbsp;Conversão Lista<BR>
                      <i [class]="getIconClass(dados.statusProcessamentoRegistros)"></i>&nbsp;Processamento Registros&nbsp;({{ dados.percentualProcessamentoRegistros }}%)<BR>
                      <i [class]="getIconClass(dados.statusProcessamentoDocumentos)"></i>&nbsp;Processamento Documentos&nbsp;({{ dados.percentualProcessamentoDocumentos }}%)<BR>
                      <i [class]="getIconClass(dados.statusVerificaProcessamento)"></i>&nbsp;Verifica Processamento&nbsp;({{ dados.percentualChecagemStatus }}%)<BR>
                    </td>
                    <td style="text-align: center">
                        <p-button
                        icon="pi pi-external-link"
                        label="Detalhar"
                        styleClass="p-button-rounded p-button-secondary p-button-text"
                        (click)="detalhaLista(dados)"></p-button><BR>
                        <p-button
                          icon="pi pi-file-export"
                          label="Exportar Erros"
                          styleClass="p-button-rounded p-button-secondary p-button-text"
                          (click)="exportarDados(dados)"></p-button>
                    </td>
                </tr>

            </ng-template>
        </p-table>

    </div>
