import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  apiUrl: string = `${environment.backendUrl}/menus`;

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
    }
  }

  httpOptionsMultiPart = {
    headers: {
      'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
    }
  }

  habilitaRegras(): Observable<boolean> {
    let searchParams = new HttpParams();
    return this.http.get<boolean>(`${this.apiUrl}/regras`,
      { params: searchParams, headers: this.httpOptions.headers });
  }
}
