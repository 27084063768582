<app-menu-bar></app-menu-bar>
<div class="grupo-container">
  <div style="text-align: center">Manutenção de Grupos</div>
  <p-toolbar>
    <ng-template pTemplate="right">
      <div class="card flex justify-content-center">
        <p-toast></p-toast>
      </div>
      <button
        pButton
        pRipple
        icon="pi pi-plus"
        label="Adicionar"
        class="p-button-info mr-2"
        (click)="add()"
      ></button>
    </ng-template>
  </p-toolbar>

  <div class="table-container">
    <p-table
      [value]="grupos"
      [paginator]="true"
      [rows]="15"
      [rowsPerPageOptions]="[10, 15, 20]"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{first} até {last} (Total de Registros: {totalRecords})"
      styleClass="p-datatable-gridlines"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
      [globalFilterFields]="[
        'dados.nome',
        'dados.cadastro',
        'dados.status',
        'dados.site',
        'dados.email',
        'dados.telefone'

      ]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="id">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Id
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="id"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="id"
                  display="menu"
                  matchMode="contains"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                ></p-columnFilter>
              </div>
            </div>
          </th>
          <th pSortableColumn="nome">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Grupo
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="nome"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="nome"
                  display="menu"
                  matchMode="contains"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                ></p-columnFilter>
              </div>
            </div>
          </th>
          <th pSortableColumn="cadastro">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Data de Cadastro
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="amount"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="cadastro"
                  display="menu"
                  matchMode="contains"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="status">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Status
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="status"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="status"
                  display="menu"
                  matchMode="contains"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="site">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Site
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="description"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="site"
                  display="menu"
                  matchMode="contains"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="email">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Email
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="category.name"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="category.name"
                  display="menu"
                  matchMode="contains"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                ></p-columnFilter>
              </div>
            </div>
          </th>

          <th pSortableColumn="telefone">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Telefone
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="category.name"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="category.name"
                  display="menu"
                  matchMode="contains"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                ></p-columnFilter>
              </div>
            </div>
          </th>
          <th pSortableColumn="client_id">
            <div class="flex justify-content-between align-items-center">
              <div class="flex justify-content-between align-items-center">
                Client ID
              </div>
              <div class="flex justify-content-between align-items-center">
                <p-sortIcon field="client_id"></p-sortIcon>
                <p-columnFilter
                  type="text"
                  field="client_id"
                  display="menu"
                  matchMode="contains"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false">
                </p-columnFilter>
              </div>
            </div>
          </th>
          <!--th style="text-align: center">Operação</th-->
          <th>
            <div class="flex justify-content-center">
              Operação
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-dados>
        <tr>
          <td style="text-align: center">{{ dados.id }}</td>
          <td style="text-align: center">{{ dados.nome }}</td>
          <td style="text-align: center">{{ dados.cadastro | date:'dd/MM/yyyy'}}</td>
          <td style="text-align: center">{{ dados.status }}</td>
          <td style="text-align: center">{{ dados.site }}</td>
          <td style="text-align: center">{{ dados.email }}</td>
          <td style="text-align: center">{{ dados.telefone | phone }}</td>
          <td style="text-align: center">{{ dados.clientId }}</td>
          <td style="text-align: center">
            <p-button
              icon="pi pi-file-edit"
              styleClass="p-button-rounded p-button-secondary p-button-text"
              pTooltip="Editar"
              (onClick)="editarGrupo(dados)"
            >
            </p-button>
            <p-button
              icon="pi pi-trash"
              styleClass="p-button-rounded p-button-secondary p-button-text"
              pTooltip="Apagar"
              (onClick)="deletarGrupo(dados.id)"
            >
            </p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-confirmDialog appendTo="body" icon="pi pi-exclamation-triangle" [position]="'top'"
    [baseZIndex]="10000"></p-confirmDialog>
    <p-dialog
      header="Cadastro de grupos"
      [(visible)]="visible"
      [style]="{ width: '750px' }"
      [modal]="true"
      (onHide)="hideDialog()"
      styleClass="p-fluid"
    >
    <ng-template pTemplate="header">
      <h2 *ngIf="grupo.id">Editar grupo</h2>
      <h2 *ngIf="!grupo.id">Novo grupo</h2>
    </ng-template>

    <ng-template pTemplate="content">
      <div class="grid">

        <div class="p-field col-6 p-fluid">
          <label for="name">Nome: </label>
          <input
            type="text"
            pInputText
            id="name"
            [(ngModel)]="grupo.nome"
          />
          <small class="p-error" *ngIf="submitted && !grupo.nome">Nome do grupo é obrigatório.</small>
        </div>
        <div class="p-field col-6 p-fluid">
          <label for="data">Data: </label>
          <input
            type="date"
            pInputText
            id="data"
            defaultDate="dataDefault"
            [(ngModel)]="grupo.cadastro"
            disabled
          />
        </div>
        <div class="p-field col-6 p-fluid">
          <label for="name">Status: </label>
          <p-dropdown
            [options]="statusGrupo"
            optionValue="valor"
            optionLabel="chave"
            [(ngModel)]="grupo.status"
          >
          </p-dropdown>
          <small class="p-error" *ngIf="submitted && !grupo.status">Status é obrigatório.</small>
        </div>
        <div class="p-field col-6 p-fluid">
          <label for="name">Site: </label>
          <input
            type="text"
            pInputText
            id="site"
            [(ngModel)]="grupo.site"
          />
        </div>
        <div class="p-field col-6 p-fluid">
          <label for="email">Email: </label>
          <input
            type="text"
            pInputText
            id="email"
            [(ngModel)]="grupo.email"
          />
          <small class="p-error" *ngIf="submitted && !grupo.email">Email é obrigatório.</small>
        </div>
        <div class="p-field col-6 p-fluid">
          <label for="telefone">Telefone: </label>
          <input
            type="number"
            pInputText
            id="telefone"
            [(ngModel)]="grupo.telefone"/>
        </div>
        <div class="p-field col-6 p-fluid">
          <label for="ĉlientId">Client ID:</label>
          <input type="text" pInputText id="clientId"
            [(ngModel)]="grupo.clientId"/>
        </div>
        <div class="p-field col-6 p-fluid">
          <label for="ĉlientSecret">Client Secret:</label>
          <input type="text" pInputText id="clientSecret"
            [(ngModel)]="grupo.clientSecret"/>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button
      pButton
      label="{{ grupo.id ? 'Atualizar' : 'Salvar' }}"
      icon="pi pi-check"
      class="b-salvar"
      (click)="salvarGrupo()"
    ></button>
      <p-button
        pRipple
        label="Cancelar"
        icon="pi pi-times"
        class="b-cancelar"
        styleClass="p-button-secondary"
        (click)="hideDialog()"
      ></p-button>
    </ng-template>
    </p-dialog>
  </div>
</div>
